import React, { useState } from 'react';
import './DesktopApp.scss';
import BGDesktop from './img/bg/Desktop-Background.webp';
import ImgLormnHeader from './img/LMN-logo-Header.png';
import ImgLormnFooter from './img/LMN-logo-Footer.png';
import ImgLormnDownArrow from './img/lormn-down-arrow.webp';

export default function DesktopApp() {
    const [scrollY, setScrollY] = useState(document.body.scrollTop);
    const [vpWidth, setVpWidth] = useState(window.innerWidth);

    document.body.addEventListener('scroll', () => {
        setScrollY(document.body.scrollTop);
    });

    window.addEventListener('resize', () => {
        setVpWidth(window.innerWidth);
    });

    return (
        <div className="desktop">
            <img className='background' src={BGDesktop}/>

            <span className='fellix--medium' style={{
                position: 'absolute',
                left: '16.796875vw',
                top: '59.86328125vw',
                width: '37.20703125vw',
                height: '25.9765625',
                fontSize: '2.6vw',
                lineHeight: '3.8vw',
            }}>
                <div id='anchor-about' style={{
                    position: 'relative',
                    top: '-8vw',
                }}/>
                Lowe&apos;s Media Network<br/>
                is a robust portfolio of omni&ndash;<br/>
                channel advertising services<br/>
                powered by Lowe&apos;s customer<br/>
                data, real-time shopping<br/>
                trends and proven marketing<br/>
                expertise.
            </span>

            <span className='fellix--regular' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '61.328125vw',
                width: '28.71551513671875vw',
                height: '21.97265625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw'
            }}>
                <br/><br/>
                Lowe&apos;s Media Network is one of the<br/>
                fastest-growing major retail media networks. Our customizable<br/>
                advertising solutions and proprietary customer insights<br/>
                allow our brand partners to connect with Lowe&apos;s customers<br/>
                at all stages of their shopping journey – from research and<br/>
                evaluation to consideration and purchase.
            </span>

            <span className='fellix--regular' style={{
                position: 'absolute',
                left: '11.89375vw',
                top: '99.70703125vw',
                width: '27.0843505859375vw',
                height: '7.6171875vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color:'black'
            }}>
                DIY and Pro customers turn to Lowe&apos;s for more than just shopping.<br/>
                <br/>
                Customers come to Lowe&apos;s to get inspiration and innovation<br/>
                for new projects, service and insight to pick the right products<br/>
                and experiences that drive awareness and purchases.<br/>
                With Americans spending more time at home, there&apos;s been<br/>
                a renewed spotlight on making sure those homes not only<br/>
                provide shelter, but also enhance their lives.<br/>
                <br/>
                Lowe&apos;s has the ability to reach over 120M unique consumers<br/>
                supported by our rich first&ndash; and third&ndash;party data.<br/>
                Our Lowe&apos;s Media Network draws on this reach and uses<br/>
                unique insights to help brands meet a wide range of<br/>
                marketing objectives, from share&ndash;of&ndash;shelf and new product<br/>
                launches to seasonal promotion and multi&ndash;product sales.
            </span>

            <span className='fellix--medium' style={{
                position: 'absolute',
                left: '56.440625vw',
                top: '101.36640625vw',
                width: '29.7271728515625vw',
                height: '10.83984375vw',
                fontSize: '2.9vw',
                lineHeight: '3.8vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                Unparalleled access<br/>
                to our diverse and<br/>
                growing audience.
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '80.9470947265625vw',
                top: '117.675vw',
                width: '10.85662841796875vw',
                height: '4.6875vw',
                fontSize: '4.0vw',
                lineHeight: '5.3vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                39MM
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '80.95625vw',
                top: '122.26484375vw',
                width: '10.8489990234375vw',
                height: '4.78515625vw',
                fontSize: '1.4vw',
                lineHeight: '1.6vw'
            }}>
                average weekly visits on<br/>
                Lowe’s digital platforms.
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '16.796875vw',
                top: '142.08984375vw',
                width: '34.76409912109375vw',
                height: '6.34765625vw',
                fontSize: '2.7vw',
                lineHeight: '3.2vw'
            }}>
                <div id='anchor-solutions' style={{
                    position: 'relative',
                    top: '-8vw'
                }}/>
                Solutions tailored to<br/>
                your business ambitions.
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '16.796875vw',
                top: '149.90234375vw',
                width: '33.73870849609375vw',
                height: '7.6171875vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw'
            }}>
                Lowe&apos;s Media Network offers brands a consultative,<br/>
                strategic approach as well as a robust omnichannel and<br/>
                full-funnel suite of products and solutions that deliver<br/>
                across brands&apos; marketing and advertising objectives. With<br/>
                perpetually expanding products, rich campaign performance<br/>
                measurement and insights, and flexible and creative strategies,<br/>
                there is a portfolio of solutions for every brand&apos;s goals.
            </span>

            {/* Generates six horizontal white lines as separators between the five elements that follow */}
            { [0, 1, 2, 3, 4, 5].map(i => <div key={`hr${i}`} style={{
                backgroundColor: 'white',
                position: 'absolute',
                left: '60vw',
                top: `${143 + 5.5*i}vw`,
                width: '28.699200439453126vw',
                height: '0.1vw',
                opacity: '0.7'
            }}/>) }

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '144.72578125vw',
                width: '16.0369873046875vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.9vw'
            }}>
                Robust audience targeting
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '150.2921875vw',
                width: '15.7623291015625vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.9vw'
            }}>
                Premium native display
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '155.85859375vw',
                width: '18.20526123046875vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.9vw'
            }}>
                Sponsored product ads
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '161.425vw',
                width: '13.19122314453125vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.9vw'
            }}>
                Audience extension with programmatic activation
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '166.89375vw',
                width: '23.4771728515625vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.9vw'
            }}>
                Social media partnerships
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '171.89375vw',
                width: '23.4771728515625vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.9vw'
            }}>
                Closed-loop measurement and insights
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '13.15625vw',
                top: '190.234375vw',
                width: '27.79083251953125vw',
                height: '9.66796875vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                Lowe&apos;s is a best-in-class home improvement retailer.<br/>
                We deliver the right products with the best service and<br/>
                value, across every channel and community we serve. From<br/>
                urgent repairs to a dream remodel, we are designed<br/>
                to help our customers when they need us most. 
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '60.15625vw',
                top: '190.234375vw',
                width: '27.79083251953125vw',
                height: '9.66796875vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                With a highly engaged customer base in store<br/>
                and online, Lowe&apos;s Media Network extends<br/>
                that design to support brand partners in reaching<br/>
                our customers throughout their shopping journey.
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '1.6143798828125vw',
                top: '207.5vw',
                width: '12.29705810546875vw',
                height: '4.6875vw',
                fontSize: '4.0vw',
                lineHeight: '5.3vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                $86B
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '2vw',
                top: '212.5vw',
                width: '3.72161865234375vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.6vw'
            }}>
                in sales in 2023
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '26.15814208984375vw',
                top: '207.5vw',
                width: '8.9141845703125vw',
                height: '4.6875vw',
                fontSize: '4.0vw',
                lineHeight: '5.3vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                1,700+
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '26.4vw',
                top: '212.5vw',
                width: '6.60552978515625vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.6vw'
            }}>
                stores in the US
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '50.5767822265625vw',
                top: '207.5vw',
                width: '8.90350341796875vw',
                height: '4.6875vw',
                fontSize: '4.0vw',
                lineHeight: '5.3vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                39MM
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '50.390625vw',
                top: '212vw',
                width: '9.381103515625vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.6vw'
            }}>
                average weekly visits on<br/>
                Lowe&apos;s digital platforms
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '74.5767822265625vw',
                top: '207.5vw',
                width: '8.90350341796875vw',
                height: '4.6875vw',
                fontSize: '4.0vw',
                lineHeight: '5.3vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                75%/25%
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '74.390625vw',
                top: '212vw',
                width: '9.381103515625vw',
                height: '1.66015625vw',
                fontSize: '1.4vw',
                lineHeight: '1.6vw'
            }}>
                DIY and Pro<br/>
                shopper split
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '14.6015625vw',
                top: '240.13671875vw',
                width: '36.309814453125vw',
                height: '6.8359375vw',
                fontSize: '2.6vw',
                lineHeight: '3.8vw',
                color: 'rgba(1, 33, 105, 1)',
                letterSpacing: '-0.05vw',
                textAlign: 'center'
            }}>
                Welcome to The<br/>
                Lowe&apos;s Media Network.
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '57.12890625vw',
                top: '239.55078125vw',
                width: '27.618408203125vw',
                height: '3.3203125vw',
                fontSize: '1.3vw',
                lineHeight: '1.9vw',
                color: 'black'
            }}>
                Get in touch to see how, together, we can move<br/>
                the needle for your brand.
            </span>

            <a className='fellix--600-email-link' style={{
                position: 'absolute',
                left: '57.5vw',
                top: '245.1171875vw',
                width: '23.35052490234375vw',
                height: '1.5625vw',
                fontSize: '1.8vw',
                fontWeight: 'bold',
                lineHeight: '1.9vw'
            }}
            href='mailto:lowesmedianetwork@lowes.com'>
                lowesmedianetwork@lowes.com
            </a>

            <img src={ImgLormnFooter} style={{
                position: 'absolute',
                left: '9vw',
                top: '267.67578125vw',
                width: '17vw',
                height: '11.23046875vw'
            }}/>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '34.375vw',
                top: '270.41015625vw',
                width: '8.7677001953125vw',
                height: '1.46484375vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                PRIVACY &amp; USE
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '34.375vw',
                top: '272.4609375vw',
                width: '3.50341796875vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                <a className='link' href='https://www.lowes.com/l/terms-and-conditions-of-use.html'>Terms</a>
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '34.375vw',
                top: '274.51171875vw',
                width: '10.5499267578125vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                <a className='link' href='https://www.lowes.com/l/privacy-and-security-statement.html'>Privacy Statement</a>
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '34.375vw',
                top: '276.5625vw',
                width: '10.7086181640625vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                <a className='link' href='https://www.lowes.com/l/privacy-and-security-statement.html#interestadvertising'>Interest-Based Ads</a>
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '52.05078125vw',
                top: '272.0703125vw',
                width: '10.15625vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                <a className='link' href='https://www.lowes.com/l/privacy-and-security-statement.html#addnoticecaliforniacustomer'>CA Privacy Rights</a>
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '52.05078125vw',
                top: '274.4140625vw',
                width: '8.5968017578125vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                <a className='link' href='https://www.lowes.com/l/privacy-and-security-statement.html#MoreAboutSale'>Do Not Sell My</a>
            </span>

            <span className='fellix--400' style={{
                position: 'absolute',
                left: '52.05078125vw',
                top: '276.7578125vw',
                width: '12.00714111328125vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'black'
            }}>
                <a className='link' href='https://www.lowes.com/l/privacy-and-security-statement.html#MoreAboutSale'>Personal Information</a>
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '71.875vw',
                top: '272.265625vw',
                width: '7.5897216796875vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                <a className='link' href='https://www.lowes.com/'>LOWES.COM</a>
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '71.875vw',
                top: '274.31640625vw',
                width: '8.673095703125vw',
                height: '1.5625vw',
                fontSize: '1.3vw',
                lineHeight: '2.1vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                <a className='link' href='https://corporate.lowes.com/investors'>INVESTOR SITE</a>
            </span>

            {/* Keep the following elements at bottom so they have greatest z-index */}

            <div className='header-bg' style={{
                opacity: Math.min(1, scrollY * vpWidth / 60000.0)
            }}/>

            {/* white horizontal line at top of page */}
            <div style={{
                backgroundColor: 'white',
                position: 'absolute',
                left: '10vw',
                top: '10.69vw',
                width: '87.5vw',
                height: '0.15vw',
                opacity: Math.max(0, 0.8 - scrollY * 200.0 / vpWidth)
            }}/>
            <div>
                <img src={ImgLormnDownArrow} style={{
                    position: 'fixed',
                    left: '-0.09920000433921814vw',
                    top: '-0.2992000102996826vw', 
                    width: '15.2vw',
                    height: '15.8vw',
                    filter: 'drop-shadow(0vw 0.1vw 0.4vw rgba(0, 0, 0, 0.25))'
                }}/>
            </div>
            <img src={ImgLormnHeader} style={{
                position: 'fixed',
                left: '3.95vw',
                top: '4vw',
                width: '8.5vw',
                height: '5vw'
            }}/>

            <a 
                className={'fellix--600 topnavlink ' + (scrollY / vpWidth < 0.015 ? 'attop' : 'notattop')} 
                onClick={() => { document.body.querySelector('#anchor-about')?.scrollIntoView({behavior: 'smooth'}); }}
                style={{
                    position: 'fixed',
                    left: '70.3125vw',
                    top: Math.max(0.025 * vpWidth, 0.042 * vpWidth - scrollY),
                    width: '4.1168212890625vw',
                    height: '1.85546875vw',
                    fontSize: '1.5vw',
                    lineHeight: '2.2vw',
                }}>
                About
            </a>

            <a 
                className={'fellix--600 topnavlink ' + (scrollY / vpWidth < 0.015 ? 'attop' : 'notattop')} 
                onClick={() => { document.body.querySelector('#anchor-solutions')?.scrollIntoView({behavior: 'smooth'}); }}
                style={{
                    position: 'fixed',
                    left: '77.1484375vw',
                    top: Math.max(0.025 * vpWidth, 0.042 * vpWidth - scrollY),
                    width: '6.13861083984375vw',
                    height: '1.85546875vw',
                    fontSize: '1.5vw',
                    lineHeight: '2.2vw'
                }}>
                Solutions
            </a>

            <a href='mailto:lowesmedianetwork@lowes.com'><div className='fellix--600 contactus' style={{
                position: 'fixed',
                left: '85.44843750000001vw',
                top: Math.max(0.023 * vpWidth, 0.04 * vpWidth - scrollY),
                width: '12.109375vw',
                height: '2.63671875vw',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.3vw'
            }}>
                Contact Us
            </div></a>

        </div>
    );
}
