/* eslint-disable indent */

import React from 'react';
import './MobileApp.scss';
import BGMobile from './img/bg/Mobile-Background.webp';
import ImgLormnHeader from './img/LMN-logo-Header.png';
import ImgLormnFooter from './img/LMN-logo-Footer.png';
import ImgLormnDownArrow from './img/lormn-down-arrow.webp';

export default function MobileApp() {
    return (
        <div className='mobile'>
            {/* Keep background as first element so it has lowest z-index */}
            <img className='background' src={BGMobile}/>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '19.6875vw',
                top: '93.4375vw',
                width: '78.056640625vw',
                height: '40vw',
                fontSize: '4.6875vw',
                lineHeight: '6.875vw'
            }}>
                Lowe&apos;s Media Network<br/>
                is a robust portfolio of omni-channel<br/>
                advertising services powered by<br/>
                Lowe&apos;s customer data, real-time<br/>
                shopping trends and proven<br/>
                marketing expertise.
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '19.0625vw',
                top: '149.375vw',
                width: '73.5205078125vw',
                height: '92.1875vw',
                fontSize: '4.0625vw',
                lineHeight: '6.25vw'
            }}>
                Lowe&apos;s Media Network is one of<br/>
                the fastest-growing major retail media<br/>
                networks. Our customizable advertising<br/>
                solutions and proprietary customer<br/>
                insights allow our brand partners<br/>
                to connect with Lowe&apos;s customers<br/>
                at all stages of their shopping<br/>
                journey – from research and evaluation<br/>
                to consideration and purchase.

            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '4.6875vw',
                top: '245vw',
                width: '68.53515625vw',
                height: '21.875vw',
                fontSize: '5.625vw',
                lineHeight: '7.5vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                Unparalleled access to our<br/>
                diverse and growing<br/>
                audience.
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '4.6875vw',
                top: '275vw',
                width: '68.9501953125vw',
                height: '79.375vw',
                fontSize: '3.75vw',
                lineHeight: '5vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                DIY and Pro customers turn to Lowe&apos;s for <br/>
                more than just shopping.<br/>
                <br/>
                Customers come to Lowe&apos;s to get inspiration and<br/>
                innovation for new projects, service and insight to<br/>
                pick the right products and experiences that <br/>
                drive awareness and purchases. With Americans<br/>
                spending more time at home, there&apos;s been a<br/>
                renewed spotlight on making sure those homes not<br/>
                only provide shelter, but also enhance their lives.<br/>
                <br/>
                Lowe&apos;s has the ability to reach over 120M unique<br/>
                consumers supported by our rich first&ndash; and<br/>
                third&ndash;party data. Our Lowe&apos;s Media Network draws<br/>
                on this reach and uses unique insights to help<br/>
                brands meet a wide range of marketing objectives,<br/>
                from share&ndash;of&ndash;shelf and new product launches to<br/>
                seasonal promotion and multi&ndash;product sales.
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '52.0625vw',
                top: '393vw',
                width: '29.8095703125vw',
                height: '12.8125vw',
                fontSize: '10.625vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                39MM
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '50.8875vw',
                top: '405vw',
                width: '34.716796875vw',
                height: '15.3125vw',
                fontSize: '4.375vw',
                lineHeight: '5vw'
            }}>
                average weekly visits on<br/>
                Lowe’s digital platforms.
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '4.6875vw',
                top: '455.5625vw',
                width: '74.1943359375vw',
                height: '14.375vw',
                fontSize: '5.625vw',
                lineHeight: '7.5vw'
            }}>
                Solutions tailored to<br/>
                your business ambitions.
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '4.6875vw',
                top: '475vw',
                width: '79.9267578125vw',
                height: '36.5625vw',
                fontSize: '4.375vw',
                lineHeight: '6.25vw'
            }}>
                Lowe&apos;s Media Network offers brands<br/>
                a consultative, strategic approach as well as a<br/>
                robust omni&ndash;channel and full-funnel suite of<br/>
                products and solutions that deliver across<br/>
                brands&apos; marketing and advertising objectives.<br/> 
                With perpetually expanding products, rich<br/>
                campaign performance measurement and<br/> 
                insights, and flexible and creative strategies,<br/>
                there is a portfolio of solutions for every<br/>
                brand&apos;s goals.
            </span>
            <div style={{'height': '300px', 'overflowY': 'scroll'}}>
            { [
                [0, 'Robust audience targeting'],
                [1, 'Premium native display'],
                [2, 'Sponsored product ads'],
                [3, 'Audience extension with programmatic activation'],
                [4, 'Social media partnership'],
                [5, 'Closed-loop measurement and insights']
            ].map(([i, s]) => <div key={`line${i}`} className='fellix--600' style={{
                position: 'absolute',
                left: '4.6875vw',
                top: (i===3) ? '604.312vw':`${545.25 + 20.6875*parseInt(i.toString())}vw`,
                fontSize: '5.625vw'
            }}>{s}</div>) }</div>

            {/* Generates six horizontal white lines as separators between the five elements that follow */}
            { [0, 1, 2, 3, 4, 5].map(i => <div key={`hr${i}`} style={{
                backgroundColor: 'white',
                position: 'absolute',
                left: '4.6875vw',
                top: `${540 + 20.6875*i}vw`,
                width: '89.6875vw',
                height: '0.3vw',
                opacity: '1.0'
            }}/>) }

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '4.6875vw',
                top: '690vw',
                fontSize: '3.75vw',
                lineHeight: '5.9375vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                Lowe&apos;s is a best-in-class home improvement retailer.<br/>
                We deliver the right products with the best service and<br/>
                value, across every channel and community we serve.<br/>
                From urgent repairs to a dream remodel, we are<br/>
                designed to help our customers when they need us<br/>
                most.<br/> <br/>
                With a highly engaged customer base in store<br/>
                and online, Lowe&apos;s Media Network extends<br/>
                that design to support brand partners in reaching<br/>
                our customers throughout their shopping journey.
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '60.3125vw',
                top: '765vw',
                fontSize: '10.625vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                $86B
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.625vw',
                top: '778vw',
                fontSize: '4.375vw'
            }}>
                in sales in 2023
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '60.3125vw',
                top: '804vw',
                fontSize: '10.625vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                1,700+
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '60.625vw',
                top: '817vw',
                fontSize: '4.375vw'
            }}>
                stores in the US
            </span>

            <span className='fellix--700' style={{
                position: 'absolute',
                left: '60.3125vw',
                top: '838vw',
                fontSize: '10.625vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                39MM
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '59vw',
                top: '849vw',
                fontSize: '4.375vw'
            }}>
                average weekly<br/>
                visits on Lowe&apos;s<br/> 
                digital platforms
            </span>
            <span className='fellix--700' style={{
                position: 'absolute',
                left: '56vw',
                top: '880vw',
                fontSize: '10.625vw',
                color: 'rgba(47, 163, 223, 1)'
            }}>
                75%/25%
            </span>

            <span className='fellix--600' style={{
                position: 'absolute',
                left: '64vw',
                top: '892vw',
                fontSize: '4.375vw'
            }}>
                DIY and Pro<br/>
                shopper split
            </span>
            <div className='fellix--700' style={{
                position: 'absolute',
                left: '0vw',
                top: '950vw',
                width: '100vw',
                height: '15vw',
                fontSize: '5.625vw',
                lineHeight: '7.5vw',
                color: 'rgba(1, 33, 105, 1)',
                textAlign: 'center'
            }}>
                Welcome to The<br/>
                Lowe&apos;s Media Network.
            </div>

            <div className='fellix--600' style={{
                position: 'absolute',
                left: '0vw',
                top: '968.5vw',
                width: '100vw',
                height: '10vw',
                fontSize: '4.375vw',
                lineHeight: '5.9375vw',
                color: 'rgba(1, 33, 105, 1)',
                textAlign: 'center'
            }}>
                Get in touch to see how, together, we<br/>
                can move the needle for your brand.
            </div>

            <div className='fellix--700' style={{
                position: 'absolute',
                left: '0vw',
                top: '983vw',
                width: '100vw',
                height: '10vw',
                fontSize: '4.575vw',
                fontWeight: 'bold',
                lineHeight: '5.9375vw',
                color: 'rgba(1, 33, 105, 1)',
                textAlign: 'center'
            }}>
                <a className='email-link' href='mailto:lowesmedianetwork@lowes.com'>lowesmedianetwork@lowes.com</a>
            </div>

            <img src={ImgLormnFooter} style={{
                position: 'absolute',
                left: '24vw',
                top: '995vw',
                width: '52vw',
                height: '35.9375vw'
            }}/>

            <div style={{
                position: 'absolute',
                left: '7.5vw',
                top: '1080vw',
                width: '40vw',
                height: '50vw'
            }}>
                <span className='fellix--700' style={{
                    position: 'absolute',
                    left: '0vw',
                    top: '0vw',
                    fontSize: '4.375vw',
                    color: 'rgba(1, 33, 105, 1)'
                }}>
                    PRIVACY &amp; USE
                </span>

                { [
                    ['1', 'Terms', 'https://www.lowes.com/l/terms-and-conditions-of-use.html'],
                    ['2', 'Privacy Statement', 'https://www.lowes.com/l/privacy-and-security-statement.html'],
                    ['3', 'Interest-Based Ads', 'https://www.lowes.com/l/privacy-and-security-statement.html#interestadvertising'],
                    ['4', 'CA Privacy Rights', 'https://www.lowes.com/l/privacy-and-security-statement.html#addnoticecaliforniacustomer'],
                    ['5', 'Do Not Sell My', 'https://www.lowes.com/l/privacy-and-security-statement.html#MoreAboutSale'],
                    ['6', 'Personal Information', 'https://www.lowes.com/l/privacy-and-security-statement.html#MoreAboutSale'],
                ].map(([i, text, url]) => <a key={`link${i}`} href={url} className='fellix--400 link' style={{
                    position: 'absolute',
                    left: '0vw',
                    top: `${parseInt(i)*7.5}vw`,
                    fontSize: '4.0625vw',
                    color: 'rgba(1, 33, 105, 1)'
                }}>{text}</a>)}
            </div>

            <a className='fellix--700 link' href='https://www.lowes.com/' style={{
                position: 'absolute',
                left: '60vw',
                top: '1080.5vw',
                fontSize: '4.375vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                LOWES.COM
            </a>

            <a className='fellix--700 link' href='https://corporate.lowes.com/investors' style={{
                position: 'absolute',
                left: '60vw',
                top: '1090vw',
                fontSize: '4.375vw',
                color: 'rgba(1, 33, 105, 1)'
            }}>
                INVESTOR SITE
            </a>

            {/* Keep these elements at end so they have highest z-index */}

            <div className='header-bg'/>
            <img src={ImgLormnDownArrow} style={{
                position: 'fixed',
                left: '-1.875vw',
                top: '-7.8125vw',
                width: '34.375vw',
                height: '35.9375vw',
                filter: 'drop-shadow(0vw 0.1vw 0.4vw rgba(0, 0, 0, 0.25))'
            }}/>
            <img src={ImgLormnHeader} style={{
                position: 'fixed',
                left: '8vw',
                top: '3vw',
                width: '18vw',
                height: '12vw'
            }}/>


            <a href='mailto:lowesmedianetwork@lowes.com'><div className='fellix--600 contactus' style={{
                position: 'fixed',
                left: '65.9375vw',
                top: '4.0625vw',
                width: '31.25vw',
                height: '6.5625vw',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.0625vw',
            }}>
                Contact Us
            </div></a>
        </div>
    );
}